import { notifications } from '@/app/notifications'
import AppButton from '@theme/components/atom/AppButton'
import AppInput from '@theme/components/atom/AppInput'
import AppTitle from '@theme/components/atom/AppTitle'
import LoadingMixin from '~/mixins/LoadingMixin'
import Modal from '@theme/components/utils/Modal/'
import ProvideValidator from '~/app/ProvideValidator'

export default {
  components: {
    AppButton,
    AppInput,
    AppTitle,
    Modal,
  },
  mixins: [LoadingMixin, ProvideValidator],
  data() {
    return {
      listName: '',
    }
  },
  methods: {
    openModal() {
      this.$nextTick(() => {
        this.$refs.shoppingListCreateModal.show()
      })
    },
    async submit() {
      this.startLoading()
      if (!(await this.$validator.validate())) {
        notifications.danger(this.$validator.errors.items[0].msg)
        this.stopLoading()
        return
      }
      this.$emit('createList', this.listName)
    },
  },
}
